<template>
  <div>
    <div class="relative bg-yellow-100 bg-opacity-50">
      <img class="sm:hidden w-full" src="/img/msubmit.png" alt="Ask Us" />
      <div
        class="absolute inset-0 flex sm:items-center mt-4 sm:mt-0 justify-center"
      >
        <h2 class="font-bold text-3xl text-primary-orange">
          Submit a Question
        </h2>
      </div>
      <img class="hidden sm:block w-full" src="/img/submit.png" alt="Ask Us" />
    </div>
    <div class="px-6 md:px-12 md:max-w-4xl md:mx-auto py-12">
      <div
        class="relative flex bg-white rounded-full shadow md:max-w-xl md:mx-auto"
      >
        <div
          class="absolute inset-y-0 left-0 w-1/2 bg-primary-orange rounded-full transform transition ease-out duration-150"
          :class="{ 'translate-x-full': mode === 'speech' }"
        ></div>
        <button
          type="button"
          @click="mode = 'text'"
          class="flex-1 py-3 text-sm md:text-base font-medium rounded-full z-10 focus:outline-none"
          :class="mode === 'text' ? 'text-white' : 'text-primary-orange'"
        >
          <i class="fas fa-font"></i> Type to Search
        </button>
        <button
          type="button"
          @click="mode = 'speech'"
          class="flex-1 py-3 text-sm md:text-base font-medium rounded-full z-10 focus:outline-none"
          :class="mode === 'speech' ? 'text-white' : 'text-primary-orange'"
        >
          <i class="fas fa-microphone"></i> Speech to Text
        </button>
      </div>
      <p class="leading-tight py-6 text-sm">
        Submit a personal question or suggest a new topic. We will do our best
        to answer your queries.
      </p>
      <form ref="questionForm" v-if="mode === 'text'" @submit.prevent class="text-sm space-y-4">
        <div>
          <label for="phone-number" class="sr-only">Phone Number</label>
          <input
            id="phone-number"
            placeholder="Phone Number (optional)"
            v-model="phone"
            class="w-full px-4 py-3 border rounded-lg shadow focus:outline-none focus:ring focus:ring-primary-orange focus:ring-opacity-50 transition ease-in duration-150"
          />
        </div>
        <div>
          <label for="email-address" class="sr-only">Email Address</label>
          <input
            type="email"
            id="email-address"
            v-model="email"
            placeholder="Email Address (optional)"
            class="w-full px-4 py-3 border rounded-lg shadow focus:outline-none focus:ring focus:ring-primary-orange focus:ring-opacity-50 transition ease-in duration-150"
          />
        </div>
        <div>
          <label for="category" class="sr-only">Question Category</label>
          <select
            id="category"
            v-model="categories"
            class="w-full px-4 py-3 border rounded-lg shadow focus:outline-none focus:ring focus:ring-primary-orange focus:ring-opacity-50 appearance-none bg-white transition ease-in duration-150"
          >
            <option selected value="">
              Select Question Category (optional)
            </option>
            <option
              v-for="(topic, i) in topics"
              :key="`topic-option-${i}`"
              :value="topic.label"
            >
              {{ topic.label }}
            </option>
          </select>
        </div>
        <div>
          <label for="question" class="sr-only">Question</label>
          <textarea
            rows="6"
            id="question"
            v-model="question"
            placeholder="Ask a question (required)"
            class="w-full px-4 py-3 border rounded-lg shadow focus:outline-none focus:ring focus:ring-primary-orange focus:ring-opacity-50 transition ease-in duration-150"
          ></textarea>
        </div>
        <div class="md:w-64">
          <Button class="w-full" v-on:click="postQuestion">Submit Question</Button>
        </div>
      </form>
      <form v-if="mode === 'speech'" @suggest.prevent class="space-y-4">
        <p class="leading-tight text-sm">
          <span>Press this button</span>
          <svg
            class="h-8 w-8 inline-block mx-1 text-primary-green"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <circle
              cx="12"
              cy="12"
              r="9"
              stroke="currentColor"
              stroke-width="2"
            />
            <circle cx="12" cy="12" r="6" fill="currentColor" />
          </svg>
          <span>to speak.</span>
        </p>
        <div>
          <label for="phone-number" class="sr-only">Phone Number</label>
          <AudioInput id="phone-number" placeholder="Phone Number (optional)" />
        </div>
        <div>
          <label for="email-address" class="sr-only">Email Address</label>
          <AudioInput
            id="email-address"
            placeholder="Email Address (optional)"
          />
        </div>
        <div>
          <label for="category" class="sr-only">Question Category</label>
          <AudioInput
            id="category"
            placeholder="Select Question Category (optional)"
          />
        </div>
        <div>
          <label for="question" class="sr-only">Question</label>
          <AudioInput id="question" placeholder="Ask a Question (required)" />
        </div>

        <div class="md:w-64">
          <Button class="w-full space-x-2 text-sm">
            <i class="fas fa-volume-up"></i>
            <span>Submit Question</span>
          </Button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Topics from "@/mixins/Topics";

import Button from "@/components/Button";
import AudioInput from "@/components/AudioInput";
import MultiSearch from "@/components/MultiSearch";
import SocialLinks from "@/components/SocialLinks";

export default {
  mixins: [Topics],
  components: {
    Button,
    AudioInput,
    MultiSearch,
    SocialLinks,
  },
  data: () => ({
    mode: "text",
    phone: "",
    email: "",
    categories: "",
    question: "",
    questionid : 0
  }),
  created() {},
  computed: {},
  methods: {
    async postQuestion() {
      try {
        const question = {
              "question": this.question,              
              "original_question_language":"eng",
              "category": [this.categories]
              };
        const headers = {
          'Content-Type': 'application/json'
        }
        const response = await this.$http.post(
          "https://api.yesmyright.com/questions/", question,{
            headers: headers
          }
        );
        // JSON responses are automatically parsed.
        // console.log(response);
        this.questionid = response.id;
        // Create User (if user info available)
        if(this.phone || this.email){
          // console.log("within if condition")
          const user = {
              "emailId": this.email,
              "mobileNumber": this.phone,
              "submitted_qids":[
              {
                "qid": this.questionid,
                "answered":false
              }]};
              await this.$http.post(
                "https://api.yesmyright.com/users/", user,{
                  headers: headers
          }
        );
        }
        this.$refs.questionForm.reset();
      } catch (error) {
        console.log(error);
      }
    }
  },
};
</script>

<style></style>
